export const colors = {
  primaryDark: "#3F4756",
  purple: "#836CF2",
  grey: "#818895",
  transparentBlue: "#fafbfe",
  transparentGrey: "rgba(242, 244, 245, 0.4)",
  greyBlue: "#E3E9EC",
  lightGrey: "#F5F6F7",
  lightBlue: "#c6dfff",
  lightPurple: "#ECEFFE"
};
