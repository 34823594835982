import React from "react";
import PropTypes from "prop-types";
import "./layout.css";
import { Footer } from "../";
import Header from "../header";

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <script
          type="text/javascript"
          src="src/compontents/klaro/config.js"
        ></script>


      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
