import React, { useState, useContext, useRef, useEffect } from "react";
import {
  changeLocale,
  injectIntl,
  FormattedMessage,
  navigate
} from "gatsby-plugin-intl";
import UkFlag from "../../../images/uk.svg";
import PlFlag from "../../../images/pl.svg";
import styled from "styled-components";
import { colors } from "../../../utils/const";
import * as Scroll from "react-scroll";
import MenuContext from "../../context/";
import ArrowVertical from "../../../images/arrowVertical.svg";

const SelectLanguageWrapper = styled.div`
  position: relative;
  button {
    color: ${colors.primaryDark};
    width: 100px;
    margin-left: 23px;
    height: 60px;
    padding: 10px 0;
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg:nth-of-type(1) {
      height: 16px;
      width: 50px;
    }
    svg:nth-of-type(2) {
      margin-left: 9px;
      transform: ${props => (props.open ? "scaleY(-1)" : "scaleY(1)")};
    }
    span {
      width: 38px;
    }
    &:hover {
      color: ${colors.purple};
    }
  }
`;

const LanguageTooltip = styled.div`
  border-radius: 4px;
  border: ${props =>
    props.open ? "1px solid rgba(131, 108, 242, 0.1)" : "none"};
  background: ${colors.transparentBlue};
  transition: height 0.2s ease-out;
  position: absolute;
  top: 50px;
  left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${props => (props.open ? "45px" : "0")};
  overflow: hidden;
  div {
    cursor: pointer;
    align-items: center;
    width: 100px;
    padding: 10px 5px;
    display: flex;
    svg {
      margin-right: 18px;
      width: 30px;
      cursor: pointer;
      height: 16px;
      opacity: 0.9;
    }
    &:hover {
      color: ${colors.purple};
    }
    span {
      width: 30px;
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  margin: 0;
  margin-right: 14px;
  min-width: 400px;
  a {
    padding: 10px 23px;
    font-size: 18px;
    line-height: 44px;
    color: ${colors.primaryDark};
    text-decoration: none;
    &.active {
      color: ${colors.purple} !important;
    }
    &:hover {
      color: ${colors.purple} !important;
      cursor: pointer;
    }
  }
  @media (max-width: 901px) {
    display: none;
  }
`;

const SelectLanguage = ({ lang }) => {
  const [open, setOpen] = useState(false);

  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    if (open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <SelectLanguageWrapper
      open={open ? 1 : 0}
      ref={node}
      onClick={() => toggleOpen()}
    >
      <button>
        {lang === "en" && (
          <>
            <UkFlag />
            <span> ENG</span>
            <ArrowVertical />
          </>
        )}
        {lang === "pl" && (
          <>
            <PlFlag />
            <span> PL</span>
            <ArrowVertical />
          </>
        )}
      </button>
      <LanguageTooltip open={open ? 1 : 0}>
        {lang !== "en" && (
          <div onClick={() => changeLocale("en")}>
            <UkFlag /> ENG
          </div>
        )}
        {lang !== "pl" && (
          <div onClick={() => changeLocale("pl")}>
            <PlFlag /> PL
          </div>
        )}
      </LanguageTooltip>
    </SelectLanguageWrapper>
  );
};

const MobileMenu = ({ locationProps }) => {
  const lang = locationProps.location.pathname.slice(1, 3);

  const Context = useContext(MenuContext);

  const handleClick = menuItem => {
    if (
      typeof window !== "undefined" &&
      (window.location.href.includes("privacy-policy") ||
        window.location.href.includes("contact"))
    ) {
      Context.changeActiveMenu(menuItem);
      navigate("/");
    }
  };

  const navigateToSubpage = (e, page) => {
    e.preventDefault();
    navigate(page);
  };

  return (
    <MenuContext.Consumer>
      {menu => (
        <MenuWrapper>
          <Scroll.Link
            activeClass="active"
            to={"product"}
            spy={true}
            smooth={true}
            duration={300}
            offset={-50}
            onClick={() => handleClick("product")}
          >
            <FormattedMessage id="common_menu_product_link" />
          </Scroll.Link>
          <Scroll.Link
            activeClass="active"
            to={"about"}
            spy={true}
            smooth={true}
            duration={300}
            offset={-50}
            onClick={() => handleClick("about")}
          >
            <FormattedMessage id="common_menu_home_about_us_link" />
          </Scroll.Link>
          <Scroll.Link
            onClick={e => navigateToSubpage(e, "/contact")}
            activeClass="active"
            to={"contact"}
            spy={true}
          >
            <FormattedMessage id="common_menu_contact_link" />
          </Scroll.Link>
          <SelectLanguage lang={lang} />
        </MenuWrapper>
      )}
    </MenuContext.Consumer>
  );
};

export default injectIntl(MobileMenu);
