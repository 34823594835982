import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/const";
import Logo from "../../images/logo.svg";
import { injectIntl, Link } from "gatsby-plugin-intl";
import MobileMenu from "./mobileMenu/";
import { DesktopMenu } from "./desktopMenu/";
import { Location } from "@reach/router";

const Navbar = styled.header`
  transition: 0.4s;
  width: 100%;
  position: ${props => (props.stickyNav ? "fixed" : "absolute")};
  background: ${props =>
    props.stickyNav ? colors.transparentBlue : "transparent"};
  top: 0;
  left: 0;
  z-index: 99;

  > div {
    transition: 0.4s;
    margin: 0 auto;
    max-width: 1234px;
    height: ${props => (props.stickyNav ? "82px" : "144px")};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    &:first-of-type {
      padding: 10px 14px;
    }
    svg {
      z-index: 10;
      margin-top: 10px;
      transform: ${props => (props.stickyNav ? "scale(.95)" : "scale(1)")};
    }
  }
  @media (max-width: 900px) {
    a {
      padding: 0 5vmin;
    }
  }
`;

const Header = ({ intl }) => {
  const [stickyNav, setStickyNav] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const listenToScroll = () => {
    if (window.pageYOffset > 0) {
      setStickyNav(true);
    } else {
      setStickyNav(false);
    }
  };

  return (
    <Location>
      {locationProps => {
        return (
          <Navbar stickyNav={stickyNav ? 1 : 0}>
            <div>
              <Link to="/">
                <Logo />
              </Link>
              <DesktopMenu locationProps={locationProps} />
              <MobileMenu locationProps={locationProps} />
            </div>
          </Navbar>
        );
      }}
    </Location>
  );
};

export default injectIntl(Header);
