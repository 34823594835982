import React, { useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../../utils/const";
import {
  changeLocale,
  FormattedMessage,
  navigate,
  Link
} from "gatsby-plugin-intl";
import UkFlag from "../../../images/uk.svg";
import PlFlag from "../../../images/pl.svg";
import * as Scroll from "react-scroll";
import MenuContext from "../../context/";

const Wrapper = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;
const PurpleButton = styled.button`
  margin-top: 1rem;
  background: rgba(131, 108, 242, 1);
  box-shadow: 0px 8px 12px rgba(131, 108, 242, 0.25);
  border-radius: 8px;
  color: white;
  font-weight: 300;
  min-width: 180px;
  padding: 0 1rem;
  min-height: 50px;
  transition: transform 0.2s ease-in;
  &:hover {
    cursor: pointer;
    background: rgba(131, 108, 242, 0.9);
  }
  &:active {
    transform: translateY(2px);
  }
`;
const HamburgerButton = styled.button`
  background: none;
  border: none;
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 14px 5vmin 0 5vmin;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &:active {
    span {
      transform: rotate(30deg);
      top: 10px;
    }
  }
  span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: ${colors.purple};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 2px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 15px;
  }
  span:nth-child(4) {
    top: 28px;
    width: 80%;
  }
  .open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  .open span:nth-child(2) {
    transform: rotate(45deg);
  }
  .open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`;

const fadeInRight = keyframes`
0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

const MenuMobile = styled.div`
  > div {
    height: 100vmax;
    overflow: scroll !important;
    background: ${colors.transparentBlue};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    &.open {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      opacity: 1;
      visibility: visible;
      height: 100vmax;
      z-index: -1;
      width: 100%;
      ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: start;
        font-size: 6vmin;
        font-weight: 800;
      }
      li {
        animation: ${fadeInRight} 0.5s ease forwards;
        animation-delay: 0.35s;
        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }
        &:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(6) {
          animation-delay: 0.6s;
        }
      }
    }
    ul {
      font-size: 2rem;
      text-align: center;
      list-style: none;
      padding: 0;
      margin: 30vh 0 0 0;

      li {
        display: block;
        position: relative;
        opacity: 0;
        margin-bottom: 0.75em;
        cursor: pointer;
        &:hover {
          color: ${colors.purple};
        }
        a {
          line-height: 125%;
          text-decoration: none;
          color: ${colors.primaryDark};
          &:hover {
            color: ${colors.purple};
          }
        }
      }
    }
  }
`;

const SelectLanguageWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    color: ${colors.primaryDark};
    font-size: 18px;
    margin: 0 16px;
    display: flex;
    align-items: center;
  }
  svg {
    height: 20px;
    margin-right: 6px;
    width: 48px;
  }
`;
const LanguageSymbol = styled.span`
  color: ${props => (props.active ? colors.primaryDark : colors.grey)};
`;

const MenuExpanded = props => {
  const { lang } = props;
  const Context = useContext(MenuContext);

  const handleScrollLink = menuItem => {
    if (
      typeof window !== "undefined" &&
      (window.location.href.includes("privacy-policy") ||
        window.location.href.includes("contact"))
    ) {
      Context.changeActiveMenu(menuItem);
      navigate("/");
    }
    props.handleToggle();
  };

  const SelectLanguage = ({ lang }) => {
    return (
      <SelectLanguageWrapper>
        <div onClick={() => props.handleToggle(changeLocale("en"))}>
          <UkFlag />
          <LanguageSymbol active={lang === "en" ? 1 : 0}> ENG</LanguageSymbol>
        </div>
        <div onClick={() => props.handleToggle(changeLocale("pl"))}>
          <PlFlag />
          <LanguageSymbol active={lang === "pl" ? 1 : 0}> PL</LanguageSymbol>
        </div>
      </SelectLanguageWrapper>
    );
  };

  const navigateToSubpage = (e, page) => {
    e.preventDefault();
    navigate(page);
    props.handleToggle();
  };

  return (
    <MenuContext.Consumer>
      {menu => (
        <MenuMobile>
          <div className={`${props.active ? "open" : ""}`}>
            <ul>
              <li>
                <Scroll.Link
                  activeClass="active"
                  to={"product"}
                  spy={true}
                  smooth={true}
                  duration={300}
                  offset={-50}
                  onClick={() => handleScrollLink("product")}
                >
                  <FormattedMessage id="common_menu_product_link" />{" "}
                </Scroll.Link>
              </li>
              <li>
                <Scroll.Link
                  activeClass="active"
                  to={"about"}
                  spy={true}
                  smooth={true}
                  duration={300}
                  offset={-50}
                  onClick={() => handleScrollLink("about")}
                >
                  <FormattedMessage id="common_menu_home_about_us_link" />
                </Scroll.Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={e => navigateToSubpage(e, "/contact")}
                >
                  <FormattedMessage id="common_menu_contact_link" />
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  onClick={e => navigateToSubpage(e, "/privacy-policy")}
                >
                  <FormattedMessage id="common_menu_privacy_policy_link" />
                </Link>
              </li>
              <li>
                <a
                  href="https://app.captio.cc/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <PurpleButton>
                    <FormattedMessage id="home_intro_purple_button" />
                  </PurpleButton>
                </a>
              </li>
              <li>
                <SelectLanguage lang={lang} />
              </li>
            </ul>
          </div>
        </MenuMobile>
      )}
    </MenuContext.Consumer>
  );
};

export function DesktopMenu({ locationProps }) {
  const [active, setActive] = useState(false);
  const lang = locationProps.location.pathname.slice(1, 3);

  const handleToggle = callback => {
    if (active) {
      setActive(false);
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    } else {
      setActive(true);
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    }
    if (callback) return callback;
  };

  const menuExpoandedProps = {
    active,
    lang,
    handleToggle
  };

  return (
    <Wrapper>
      <MenuExpanded {...menuExpoandedProps} />
      <HamburgerButton onClick={handleToggle}>
        <div className={active ? "open" : ""}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </HamburgerButton>
    </Wrapper>
  );
}
