import React from "react";

export function withLineBreaks(...nodes) {
  return nodes
    .map(node =>
      typeof node === "string"
        ? node.split("\n").map((text, index) => (
            <div key={index}>
              {text}
              <br />
            </div>
          ))
        : node
    )
    .reduce((nodes, node) => nodes.concat(node), []);
}
