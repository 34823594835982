import React from "react";
import styled from "styled-components";
import Logo from "../../images/logo.svg";
import Captio from "../../images/Capt.io.svg";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { colors } from "../../utils/const";
import { withLineBreaks } from "../../utils/withLineBreaks";
import * as klaro from '../klaro/klaro.js';
import { klaroConfig } from '../klaro/config.js';
import "../klaro/klaro.css";
const Wrapper = styled.footer`
  padding-top: 82px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    color: ${colors.grey};
    text-align: center;
    margin-bottom: 46px;
    line-height: 28px;
  }
  @media (max-width: 900px) {
    padding: 50px 5vmin 0 5vmin;
  }
`;

const LogoFooter = styled(Logo)`
  margin-bottom: 16px;
`;
const LogoMini = styled(Captio)`
  max-width: 46px;
  height: 26px;
  margin-left: 12px;
  transform: translateY(5px);
`;

const CopyrightBox = styled.div`
  margin-bottom: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    color: ${colors.primaryDark};
    display: flex;
    align-items: center;
    line-height: 100%;
    margin-bottom: 10px;
  }
  a {
    color: ${colors.grey};
    text-decoration: none;
    font-size: 12px;
    margin-left: 6px;
    margin-right: 3px;
  }
  span {
    color: ${colors.grey};
  }
  @media (max-width: 900px) {
    margin-bottom: 46px;
  }
`;

export function Footer() {
  const date = new Date();
  const intl = useIntl();

  if (typeof window !== 'undefined') {
    window.onload = async function () {
      window.klaro = klaro;
      window.klaroConfig = klaroConfig;
      klaro.setup(klaroConfig);
       klaro.defaultConfig.lang=intl.locale;
            
       var dc = document.cookie;
       if (dc == '') {
         klaro.show(klaroConfig, false);
       }
    };
  }

  const handleOnClick = async (e) => {
    e.preventDefault();
    klaro.setup(klaroConfig);
    klaro.defaultConfig.lang= intl.locale;
    await klaro.show(klaroConfig, false);
  };


  return (
    <Wrapper>
      <LogoFooter />
      <span>
        <FormattedMessage id="common_footer_message">
          {withLineBreaks}
        </FormattedMessage>
      </span>
      <CopyrightBox>
        <div>
          <FormattedMessage id="common_footer_copyright" /> {date.getFullYear()}
          <LogoMini />
        </div>
        <Link to="/privacy-policy">
          <FormattedMessage id="common_footer_privacy_policy_link" />
        </Link>
        <Link className={'klaro'} onClick={handleOnClick}>
          <FormattedMessage id="common_footer_cookie_settings_link" />
        </Link>
      </CopyrightBox>
    </Wrapper>
  );
}
