// By default, Klaro will load the config from  a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
export const klaroConfig = {
    // With the 0.7.0 release we introduce a 'version' paramter that will make
    // it easier for us to keep configuration files backwards-compatible in the future.
    version: 1,

    // You can customize the ID of the DIV element that Klaro will create
    // when starting up. If undefined, Klaro will use 'klaro'.
    elementID: 'klaro',

    // You can override CSS style variables here. For IE11, Klaro will
    // dynamically inject the variables into the CSS. If you still consider
    // supporting IE9-10 (which you probably shouldn't) you need to use Klaro
    // with an external stylesheet as the dynamic replacement won't work there.
    styling: {
        theme: ['light', 'bottom', 'wide'],
    },

    // Setting this to true will keep Klaro from automatically loading itself
    // when the page is being loaded.
    noAutoLoad: false,

    // Setting this to true will render the descriptions of the consent
    // modal and consent notice are HTML. Use with care.
    htmlTexts: true,

    // Setting 'embedded' to true will render the Klaro modal and notice without
    // the modal background, allowing you to e.g. embed them into a specific element
    // of your website, such as your privacy notice.
    embedded: false,

    // You can group services by their purpose in the modal. This is advisable
    // if you have a large number of services. Users can then enable or disable
    // entire groups of services instead of having to enable or disable every service.
    groupByPurpose: true,

    // How Klaro should store the user's preferences. It can be either 'cookie'
    // (the default) or 'localStorage'.
    storageMethod: 'cookie',

    // You can customize the name of the cookie that Klaro uses for storing
    // user consent decisions. If undefined, Klaro will use 'klaro'.
    cookieName: 'klaro',

    // You can also set a custom expiration time for the Klaro cookie.
    // By default, it will expire after 120 days.
    cookieExpiresAfterDays: 365,

    // You can change to cookie domain for the consent manager itself.
    // Use this if you want to get consent once for multiple matching domains.
    // If undefined, Klaro will use the current domain.
    //cookieDomain: '.github.com',

    // You can change to cookie path for the consent manager itself.
    // Use this to restrict the cookie visibility to a specific path.
    // If undefined, Klaro will use '/' as cookie path.
    //cookiePath: '/',

    // Defines the default state for services (true=enabled by default).
    default: false,

    // If "mustConsent" is set to true, Klaro will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declines the use of third-party services.
    mustConsent: false,

    // Show "accept all" to accept all services instead of "ok" that only accepts
    // required and "default: true" services
    acceptAll: true,

    // replace "decline" with cookie manager modal
    hideDeclineAll: false,

    // hide "learnMore" link
    hideLearnMore: false,

    // show cookie notice as modal
    noticeAsModal: false,

    // You can also remove the 'Realized with Klaro!' text in the consent modal.
    // Please don't do this! We provide Klaro as a free open source tool.
    // Placing a link to our website helps us spread the word about it,
    // which ultimately enables us to make Klaro! better for everyone.
    // So please be fair and keep the link enabled. Thanks :)
    disablePoweredBy: true,

    // you can specify an additional class (or classes) that will be added to the Klaro `div`
    //additionalClass: 'my-klaro',

    // You can define the UI language directly here. If undefined, Klaro will
    // use the value given in the global "lang" variable. If that does
    // not exist, it will use the value given in the "lang" attribute of your
    // HTML tag. If that also doesn't exist, it will use 'en'.
    lang: 'pl',
    // You can overwrite existing translations and add translations for your
    // service descriptions and purposes. See `src/translations/` for a full
    // list of translations that can be overwritten:
    // https://github.com/KIProtect/klaro/tree/master/src/translations

    // Example config that shows how to overwrite translations:
    // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
    translations: {
        // translationsed defined under the 'zz' language code act as default
        // translations.
        zz: {
            privacyPolicyUrl: '/pl/privacy-policy',
        },
        // If you erase the "consentModal" translations, Klaro will use the
        // bundled translations.
        pl: {
            privacyPolicyUrl: '/pl/privacy-policy',
            consentModal: {
                description:
                    'Ta witryna wykorzystuje pliki cookie, które pomagają w jej funkcjonowaniu oraz śledzeniu sposobu interakcji użytkowników z nią, umożliwiając nam zapewnienie lepszej i bardziej spersonalizowanej obsługi. Wszystkie pliki cookie będą wykorzystywane tylko wtedy, gdy wyrazisz na to zgodę, klikając przycisk "Zaakceptuj wszystkie". W przypadku wyboru opcji "Odrzuć niewymagane", wykorzystywane będą jedynie niezbędne do działania strony pliki cookie. Możesz również zaakceptować wybrane Cookie klikając w przycisk "Zaakceptuj wybrane".',
            },

            _ga: {
                title: '_ga',
                description:
                    "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
            },

            _gid: {
                title: '_gid',
                description:
                    "Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.",
            },

            _gat: {
                title: '_gat',
                description:
                    'This cookie is installed by Google Universal Analytics to restrain request rate and thus limit the collection of data on high traffic sites. ',
            },

            purposes: {
                necessary: {
                    title: 'Necessary',
                    description:
                        'Niezbędne pliki cookies mają kluczowe znaczenie dla podstawowych funkcji strony internetowej i bez nich strona nie będzie działać prawidłowo. Pliki te nie przechowują żadnych danych osobowych pozwalających na identyfikację użytkowników.',
                },
                performance: {
                    title: 'Performance',
                    description:
                        'Pliki cookie związane z preferencjami służą do zapisywania informacji, które dostosowują wygląd i funkcjonalność strony internetowej dla każdego użytkownika. Mogą one obejmować przechowywanie preferowanego języka, regionu, platformy czatu internetowego oraz wypełnionych formularzy. Dzięki temu użytkownik może szybciej i łatwiej korzystać z witryny, dostosowując ją do swoich potrzeb i upodobań.',
                },
                analytics: {
                    title: 'Analytics',
                    description:
                        'Pliki cookie analityczne służą do analizy interakcji użytkowników ze stroną internetową. Pomagają one dostarczać informacje o takich metrykach, jak liczba odwiedzających, współczynnik odrzuceń, źródło ruchu i wiele innych, co pozwala lepiej zrozumieć zachowanie użytkowników na stronie.',
                },
                marketing: {
                    title: 'Marketing',
                    description:
                        'Pliki cookie reklamowe umożliwiają dostarczanie użytkownikom spersonalizowanych reklam, które odnoszą się do stron, które odwiedzili wcześniej, a także pozwalają na analizowanie skuteczności kampanii reklamowej.',
                },
            },
        },
        en: {
            privacyPolicyUrl: '/en/privacy-policy',
            consentModal: {
                title: 'Cookies',
                description:
                    'This website uses cookies to help it function and to track how users interact with it, enabling us to provide a better and more personalized service. All cookies will only be used if you have given your consent by clicking the "Accept all" button. If you select the "Decline Not Required" option, only cookies necessary for the operation of the website will be used. You can also accepted selected cookies by clicking on the "Accept Selected". ',
            },

            _ga: {
                title: '_ga',
                description:
                    "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
            },

            _gid: {
                title: '_gid',
                description:
                    "Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.",
            },

            _gat: {
                title: '_gat',
                description:
                    'This cookie is installed by Google Universal Analytics to restrain request rate and thus limit the collection of data on high traffic sites. ',
            },

            purposes: {
                necessary: {
                    title: 'Necessary',
                    description:
                        "The necessary cookies files have crucial importance for basic functions of website and website won't work properly without them. These cookies don't store any personal data allowing identifying user.",
                },
                performance: {
                    title: 'Performance',
                    description:
                        "Preference cookies store information that enables a website to be customized in appearance or functionality for each user. This may involve recording the user's preferred language, region, web chat platform, and non-essential forms.",
                },
                analytics: {
                    title: 'Analytics',
                    description:
                        'Analytics cookies are used to analyze how users interact with a website. They help to provide information on metrics such as the number of visitors, rejection rate, source of traffic, and many others, allowing us to better understand user behavior on the website.',
                },
                marketing: {
                    title: 'Marketing',
                    description:
                        'Advertising cookies make it possible to provide users with personalized advertisements that relate to pages they have previously visited, and also allow the effectiveness of the advertising campaign to be analyzed.',
                },
            },
        },
    },

    // This is a list of third-party services that Klaro will manage for you.
    services: [
        {
            name: '_ga',
            default: false,
            contextualConsentOnly: true,
            purposes: ['analytics'],
        },

        {
            name: '_gid',
            default: false,
            contextualConsentOnly: true,
            purposes: ['analytics'],
        },

        {
            name: '_gat',
            default: false,
            contextualConsentOnly: true,
            purposes: ['performance'],
        },
    ],
}

export default klaroConfig
